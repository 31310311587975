export var vendors = [
    {
        "name": "1plusX AG",
        "tcf_id": "92"
    },
    {
        "name": "Active Agent (Virtual Minds GmbH)",
        "tcf_id": "40"
    },
    {
        "name": "Ad Alliance GmbH",
        "tcf_id": "788"
    },
    {
        "name": "AdDefend GmbH ",
        "tcf_id": "539"
    },
    {
        "name": "Adex (Virtual Minds GmbH)",
        "tcf_id": "44"
    },
    {
        "name": "Adform A/S",
        "tcf_id": "50"
    },
    {
        "name": "ADITION (Virtual Minds GmbH)",
        "tcf_id": "39"
    },
    {
        "name": "Adnami Aps",
        "tcf_id": "612"
    },
    {
        "name": "Adobe Audience Manager, Adobe Experience Platform",
        "tcf_id": "565"
    },
    {
        "name": "advanced store GmbH",
        "tcf_id": "195"
    },
    {
        "name": "Amazon Ad Server",
        "tcf_id": "68"
    },
    {
        "name": "Amazon Advertising",
        "tcf_id": "793"
    },
    {
        "name": "Amplified IntelligenceTechnologies",
        "tcf_id": "1195"
    },
    {
        "name": "Artefact Deutschland GmbH",
        "tcf_id": "797"
    },
    {
        "name": "AudienceProject Aps",
        "tcf_id": "394"
    },
    {
        "name": "Bertelsmann Data Service GmbH",
        "tcf_id": "920"
    },
    {
        "name": "Bertelsmann Data Service GmbH - Exchange",
        "tcf_id": "keine"
    },
    {
        "name": "Better Ads GmbH",
        "tcf_id": "1106"
    },
    {
        "name": "Bidswitch GmbH",
        "tcf_id": "128"
    },
    {
        "name": "C WIRE AG",
        "tcf_id": "1081"
    },
    {
        "name": "Cint AB",
        "tcf_id": "734"
    },
    {
        "name": "Clipflip Interactive Video Technology GmbH",
        "tcf_id": "1293"
    },
    {
        "name": "Commanders Act",
        "tcf_id": "416"
    },
    {
        "name": "Comscore B.V.",
        "tcf_id": "77"
    },
    {
        "name": "Confiant Inc.",
        "tcf_id": "56"
    },
    {
        "name": "Crimtan Holdings Limited",
        "tcf_id": "85"
    },
    {
        "name": "Criteo SA",
        "tcf_id": "91"
    },
    {
        "name": "dataXtrade GmbH",
        "tcf_id": "938"
    },
    {
        "name": "Define Media GmbH",
        "tcf_id": "440"
    },
    {
        "name": "DoubleVerify Inc._",
        "tcf_id": "126"
    },
    {
        "name": "Dynata LLC",
        "tcf_id": "110"
    },
    {
        "name": "EASY Marketing GmbH",
        "tcf_id": "796"
    },
    {
        "name": "eBay Inc",
        "tcf_id": "929"
    },
    {
        "name": "emetriq GmbH",
        "tcf_id": "213"
    },
    {
        "name": "Epsilon",
        "tcf_id": "24"
    },
    {
        "name": "Exactag GmbH",
        "tcf_id": "312"
    },
    {
        "name": "Extreme Reach, Inc",
        "tcf_id": "1002"
    },
    {
        "name": "Factor Eleven GmbH",
        "tcf_id": "795"
    },
    {
        "name": "FlashTalking",
        "tcf_id": "78"
    },
    {
        "name": "GfK SE",
        "tcf_id": "758"
    },
    {
        "name": "Goldbach Group AG",
        "tcf_id": "580"
    },
    {
        "name": "Google Advertising Products",
        "tcf_id": "755"
    },
    {
        "name": "GroupM UK Limited",
        "tcf_id": "98"
    },
    {
        "name": "GumGum, Inc.",
        "tcf_id": "61"
    },
    {
        "name": "hyScore.io",
        "tcf_id": "1201"
    },
    {
        "name": "Improve Digital",
        "tcf_id": "253"
    },
    {
        "name": "Index Exchange Inc.",
        "tcf_id": "10"
    },
    {
        "name": "INFOnline GmbH",
        "tcf_id": "730"
    },
    {
        "name": "Innovid LLC",
        "tcf_id": "452"
    },
    {
        "name": "Inskin Media LTD",
        "tcf_id": "150"
    },
    {
        "name": "Integral Ad Science, Inc.",
        "tcf_id": "278"
    },
    {
        "name": "lead alliance GmbH",
        "tcf_id": "810"
    },
    {
        "name": "LiveRamp",
        "tcf_id": "97"
    },
    {
        "name": "Lucid Holdings, LLC",
        "tcf_id": "508"
    },
    {
        "name": "Magnite, Inc.",
        "tcf_id": "52"
    },
    {
        "name": "NEXD",
        "tcf_id": "502"
    },
    {
        "name": "Nexxen Inc.",
        "tcf_id": "23"
    },
    {
        "name": "Nielsen International SA",
        "tcf_id": "812"
    },
    {
        "name": "Online Solution",
        "tcf_id": "602"
    },
    {
        "name": "OpenX",
        "tcf_id": "69"
    },
    {
        "name": "Opinary GmbH",
        "tcf_id": "488"
    },
    {
        "name": "Otto (GmbH & Co KG)",
        "tcf_id": "559"
    },
    {
        "name": "Outbrain UK Ltd",
        "tcf_id": "164"
    },
    {
        "name": "Protected Media LTD",
        "tcf_id": "762"
    },
    {
        "name": "Publicis Media GmbH",
        "tcf_id": "226"
    },
    {
        "name": "PubMatic, Inc.",
        "tcf_id": "76"
    },
    {
        "name": "Quantcast",
        "tcf_id": "11"
    },
    {
        "name": "Realytics",
        "tcf_id": "1025"
    },
    {
        "name": "Reppublika Data Analytics and Technologies GmbH",
        "tcf_id": "800"
    },
    {
        "name": "Revjet",
        "tcf_id": "759"
    },
    {
        "name": "RTB House S.A.",
        "tcf_id": "16"
    },
    {
        "name": "ShowHeroes SE",
        "tcf_id": "111"
    },
    {
        "name": "Samba TV, Inc. ",
        "tcf_id": "350"
    },
    {
        "name": "ShowHeroes SRL",
        "tcf_id": "276"
    },
    {
        "name": "Smaato, Inc.",
        "tcf_id": "82"
    },
    {
        "name": "Smart Adserver",
        "tcf_id": "45"
    },
    {
        "name": "smartclip Europe GmbH",
        "tcf_id": "115"
    },
    {
        "name": "SMARTSTREAM.TV GmbH",
        "tcf_id": "134"
    },
    {
        "name": "SoD ScreenOnDemand GmbH",
        "tcf_id": "937"
    },
    {
        "name": "Sublime",
        "tcf_id": "114"
    },
    {
        "name": "TabMo SAS (HAWK by AZERION)",
        "tcf_id": "275"
    },
    {
        "name": "TargetVideo GmbH",
        "tcf_id": "786"
    },
    {
        "name": "The Kantar Group Limited",
        "tcf_id": "345"
    },
    {
        "name": "The Reach Group GmbH",
        "tcf_id": "382"
    },
    {
        "name": "The UK Trade Desk Ltd",
        "tcf_id": "21"
    },
    {
        "name": "TripleLift, Inc.",
        "tcf_id": "28"
    },
    {
        "name": "TV SQUARED LIMITED d/b/a InnovidXP",
        "tcf_id": "985"
    },
    {
        "name": "uppr GmbH",
        "tcf_id": "459"
    },
    {
        "name": "vitrado GmbH",
        "tcf_id": "821"
    },
    {
        "name": "Xandr, Inc.",
        "tcf_id": "32"
    },
    {
        "name": "xpln.ai SAS",
        "tcf_id": "1142"
    },
    {
        "name": "Yahoo EMEA Limited",
        "tcf_id": "25"
    },
    {
        "name": "Yieldlab (Virtual Minds GmbH)",
        "tcf_id": "70"
    },
    {
        "name": "Yougov",
        "tcf_id": "726"
    },
    {
        "name": "Zeotap GmbH",
        "tcf_id": "301"
    }];